#toast-main-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.toast-container {
  position: fixed;
  z-index: 1000;
}
.toast-container.top-right {
  top: 0px;
  align-self: flex-end;
}
@keyframes toast-animation-from-bottom {
  from {
    bottom: 100px;
    opacity: 0.8;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
.toast-item-animation-bottom {
  animation: toast-animation-from-bottom 0.5s ease-in-out;
}
.toast-item {
  position: relative;
  margin: 2rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 16px;
}
